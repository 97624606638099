import React, { useState, useEffect } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { MapPin, Navigation2, Clock, AlertCircle, Shield } from "lucide-react";

const RiderLocation = () => {
  const { user } = useUserAuth();
  const [isTrackingEnabled, setIsTrackingEnabled] = useState(false);
  const [refreshRate, setRefreshRate] = useState(1000);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [currentSpeed, setCurrentSpeed] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [permissionStatus, setPermissionStatus] = useState("prompt");
  const [currentPosition, setCurrentPosition] = useState(null);

  // Verificar permisos de geolocalización al inicio
  useEffect(() => {
    checkLocationPermission();
  }, []);

  const checkLocationPermission = async () => {
    if (!navigator.geolocation) {
      showNotification("Tu navegador no soporta geolocalización", "error");
      return;
    }

    try {
      const permission = await navigator.permissions.query({
        name: "geolocation",
      });
      setPermissionStatus(permission.state);

      permission.addEventListener("change", () => {
        setPermissionStatus(permission.state);
      });
    } catch (error) {
      console.error("Error checking permission:", error);
    }
  };

  useEffect(() => {
    let watchId = null;
    let intervalId = null;

    const startTracking = () => {
      if (!navigator.geolocation) {
        showNotification("Geolocalización no disponible", "error");
        return;
      }

      // Usar watchPosition para seguimiento continuo
      watchId = navigator.geolocation.watchPosition(
        (position) => {
          setCurrentPosition(position);
          const speed = position.coords.speed || 0;
          setCurrentSpeed(speed * 3.6); // Convertir m/s a km/h
        },
        (error) => {
          handleGeolocationError(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );

      // Intervalo para guardar en Firebase
      intervalId = setInterval(() => {
        if (currentPosition) {
          saveLocationToFirebase(currentPosition, user.uid);
          setLastUpdate(new Date());
        }
      }, refreshRate);
    };

    if (isTrackingEnabled && permissionStatus === "granted") {
      startTracking();
    }

    return () => {
      if (watchId) navigator.geolocation.clearWatch(watchId);
      if (intervalId) clearInterval(intervalId);
    };
  }, [
    isTrackingEnabled,
    refreshRate,
    user.uid,
    permissionStatus,
    currentPosition,
  ]);

  const handleGeolocationError = (error) => {
    let message = "Error al obtener la ubicación";
    switch (error.code) {
      case error.PERMISSION_DENIED:
        message = "Permiso de ubicación denegado";
        setPermissionStatus("denied");
        break;
      case error.POSITION_UNAVAILABLE:
        message = "Información de ubicación no disponible";
        break;
      case error.TIMEOUT:
        message = "Tiempo de espera agotado";
        break;
      default:
        message = "Error desconocido de geolocalización";
    }
    showNotification(message, "error");
    setIsTrackingEnabled(false);
  };

  const showNotification = (message, type = "success") => {
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  const handleTrackingToggle = async () => {
    if (!isTrackingEnabled) {
      if (permissionStatus === "denied") {
        showNotification(
          "Necesitas habilitar los permisos de ubicación",
          "error"
        );
        return;
      }

      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          });
        });

        setCurrentPosition(position);
        setIsTrackingEnabled(true);
        showNotification("Seguimiento iniciado");
      } catch (error) {
        handleGeolocationError(error);
      }
    } else {
      setIsTrackingEnabled(false);
      showNotification("Seguimiento detenido");
    }
  };

  const saveLocationToFirebase = async (position, uid) => {
    try {
      const { latitude, longitude } = position.coords;
      const speedKmph = (position.coords.speed || 0) * 3.6;

      const userDocRef = doc(db, "Users", uid);
      await setDoc(
        userDocRef,
        {
          location: {
            latitude,
            longitude,
            timestamp: new Date(),
            speed: speedKmph,
            accuracy: position.coords.accuracy,
            heading: position.coords.heading,
          },
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error saving to Firebase:", error);
      showNotification("Error al guardar la ubicación", "error");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 p-4">
      <div className="max-w-md mx-auto">
        {/* Card principal */}
        <div className="bg-white rounded-2xl shadow-lg p-6 mb-4">
          {/* Estado de permisos */}
          <div className="flex items-center gap-2 mb-4">
            <Shield
              className={`w-5 h-5 ${
                permissionStatus === "granted"
                  ? "text-green-500"
                  : permissionStatus === "denied"
                  ? "text-red-500"
                  : "text-yellow-500"
              }`}
            />
            <span className="text-sm text-gray-600">
              {permissionStatus === "granted"
                ? "Permisos concedidos"
                : permissionStatus === "denied"
                ? "Permisos denegados"
                : "Permisos pendientes"}
            </span>
          </div>

          {/* Encabezado */}
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-2">
              <MapPin className="w-6 h-6 text-blue-500" />
              <h1 className="text-xl font-semibold">Rider Location</h1>
            </div>

            {/* Switch de tracking */}
            <button
              onClick={handleTrackingToggle}
              disabled={permissionStatus === "denied"}
              className={`relative inline-flex h-8 w-14 items-center rounded-full transition-colors duration-300 focus:outline-none
                ${isTrackingEnabled ? "bg-blue-500" : "bg-gray-200"}
                ${
                  permissionStatus === "denied"
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
            >
              <span
                className={`inline-block h-6 w-6 transform rounded-full bg-white shadow-lg transition-transform duration-300
                  ${isTrackingEnabled ? "translate-x-7" : "translate-x-1"}`}
              />
            </button>
          </div>

          {/* Velocidad y precisión */}
          <div className="bg-gray-50 rounded-xl p-4 mb-6">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                <Navigation2 className="w-5 h-5 text-blue-500" />
                <span className="text-gray-600">Velocidad actual</span>
              </div>
              <span className="text-2xl font-semibold text-blue-600">
                {currentSpeed.toFixed(1)} km/h
              </span>
            </div>
            {currentPosition && (
              <div className="text-sm text-gray-500">
                Precisión: ±{currentPosition.coords.accuracy.toFixed(0)}m
              </div>
            )}
          </div>

          {/* Control de intervalo */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Intervalo de actualización: {refreshRate / 1000}s
            </label>
            <input
              type="range"
              min="1000"
              max="60000"
              step="1000"
              value={refreshRate}
              onChange={(e) => setRefreshRate(Number(e.target.value))}
              disabled={isTrackingEnabled}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-blue-500 disabled:opacity-50"
            />
            <div className="flex justify-between text-xs text-gray-500 mt-1">
              <span>1s</span>
              <span>30s</span>
              <span>60s</span>
            </div>
          </div>

          {/* Última actualización */}
          {lastUpdate && (
            <div className="flex items-center gap-2 text-sm text-gray-500 mb-4">
              <Clock className="w-4 h-4" />
              <span>
                Última actualización: {lastUpdate.toLocaleTimeString()}
              </span>
            </div>
          )}

          {/* Info box */}
          <div className="bg-blue-50 rounded-xl p-4 text-sm text-blue-700">
            <div className="flex items-start gap-2">
              <AlertCircle className="w-5 h-5 flex-shrink-0 mt-0.5" />
              <div>
                <p className="font-medium mb-2">Para mejor rendimiento:</p>
                <ul className="space-y-1 list-disc list-inside ml-2">
                  <li>Mantén la aplicación abierta</li>
                  <li>No cierres esta pestaña</li>
                  <li>Completa tu perfil para el mapa en vivo</li>
                  <li>Asegúrate de tener GPS activado</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Botón de detener */}
        {isTrackingEnabled && (
          <button
            onClick={handleTrackingToggle}
            className="w-full bg-red-500 hover:bg-red-600 text-white font-medium py-3 px-4 rounded-xl shadow-lg transition-colors duration-300 flex items-center justify-center gap-2"
          >
            <MapPin className="w-5 h-5" />
            Detener seguimiento
          </button>
        )}

        {/* Toast notification */}
        {showToast && (
          <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg animate-fade-in">
            {toastMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default RiderLocation;
