import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Trash2 } from "lucide-react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase";

const MergeRunsTable = ({ startData, endData, receiverData }) => {
  const [runs, setRuns] = useState([]);
  const [uploading, setUploading] = useState(false);

  const processAndMergeData = () => {
    if (!startData || !endData) return;

    const processTable = (data, comparator) => {
      const grouped = data.reduce((acc, row) => {
        acc[row.UID] = acc[row.UID] ? comparator(acc[row.UID], row) : row;
        return acc;
      }, {});
      return Object.values(grouped);
    };

    const latestStartData = processTable(startData, (a, b) =>
      a.Time > b.Time ? a : b
    );
    const earliestEndData = processTable(endData, (a, b) =>
      a.Time < b.Time ? a : b
    );

    const mergedRuns = latestStartData
      .map((startRow) => {
        const endRow = earliestEndData.find((row) => row.UID === startRow.UID);
        if (!endRow) return null;

        const startTime = new Date(startRow.Time);
        const finishTime = new Date(endRow.Time);
        const elapsedMs = finishTime - startTime;
        const elapsedTime = new Date(elapsedMs).toISOString().substr(11, 12); // HH:mm:ss.sss

        return {
          RUID: startRow.RUID,
          StartTime: startRow.Time,
          FinishTime: endRow.Time,
          ElapsedTime: elapsedTime,
          ActiveRun: false,
          EventID: receiverData.EventID,
          NFCIdentifier: startRow.UID,
          ParkID: receiverData.ParkID,
          TrackID: receiverData.TrackID,
        };
      })
      .filter(Boolean);

    // Sort runs by ElapsedTime
    const sortedRuns = mergedRuns.sort((a, b) => {
      const timeA = a.ElapsedTime.split(":").reduce(
        (acc, val, idx) => acc + Number(val) * Math.pow(60, 2 - idx), // Convert HH:mm:ss.sss to seconds
        0
      );
      const timeB = b.ElapsedTime.split(":").reduce(
        (acc, val, idx) => acc + Number(val) * Math.pow(60, 2 - idx), // Convert HH:mm:ss.sss to seconds
        0
      );
      return timeA - timeB;
    });

    setRuns(sortedRuns);
  };

  React.useEffect(() => {
    processAndMergeData();
  }, [startData, endData]);

  const handleDeleteRow = (index) => {
    setRuns((prevRuns) => prevRuns.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      const batchUpload = runs.map((run) =>
        addDoc(collection(db, "Runs"), run)
      );
      await Promise.all(batchUpload);
      alert("Tiempos subidos exitosamente.");
      setRuns([]); // Clear runs after upload if necessary
    } catch (error) {
      console.error("Error uploading runs:", error);
      alert("Error al subir los tiempos procesados.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <TableContainer component={Paper} className="mt-6">
        <Table size="small">
          <TableHead>
            <TableRow>
              {Object.keys(runs[0] || {}).map((header) => (
                <TableCell key={header} className="font-semibold">
                  {header}
                </TableCell>
              ))}
              <TableCell className="font-semibold"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {runs.map((run, index) => (
              <TableRow key={index}>
                {Object.values(run).map((cell, cellIndex) =>
                  cellIndex === 0 ? (
                    <TableCell key={cellIndex}>
                      {cell.substring(0, 5)}
                    </TableCell>
                  ) : cellIndex === 1 || cellIndex === 2 ? (
                    <TableCell key={cellIndex}>
                      {cell.split(" ")[0] + " " + cell.split(" ")[1]}
                    </TableCell>
                  ) : cellIndex === 4 ? (
                    <TableCell key={cellIndex}>
                      {cell ? "true" : "false"}
                    </TableCell>
                  ) : cellIndex === 5 ? (
                    <TableCell key={cellIndex}>
                      {cell.substring(0, 5)}
                    </TableCell>
                  ) : cellIndex === 7 ? (
                    <TableCell key={cellIndex}>
                      {cell.substring(0, 5)}
                    </TableCell>
                  ) : cellIndex === 8 ? (
                    <TableCell key={cellIndex}>
                      {cell.substring(0, 5)}
                    </TableCell>
                  ) : (
                    <TableCell key={cellIndex}>{cell}</TableCell>
                  )
                )}
                <TableCell>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteRow(index)}
                  >
                    <Trash2 size={20} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="mt-4">
        <Button
          variant="contained"
          onClick={() => {
            if (runs.length === 0) return;

            const confirmUpload = window.confirm(
              `Estás seguro de que deseas subir ${runs.length} tiempos procesados?`
            );

            if (!confirmUpload) return;

            handleUpload();
          }}
          disabled={uploading}
        >
          {uploading ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            "Subir tiempos procesados"
          )}
        </Button>
      </div>
    </div>
  );
};

export default MergeRunsTable;
