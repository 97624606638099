import {
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Trash2 } from "lucide-react";
import { useState } from "react";

export const CsvDataTable = ({ data, setData }) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectRow = (index) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(index)
        ? prevSelected.filter((i) => i !== index)
        : [...prevSelected, index]
    );
  };

  const handleDeleteRow = (index) => {
    setData((prevData) => prevData.filter((_, i) => i !== index));
    setSelectedRows((prevSelected) => prevSelected.filter((i) => i !== index));
  };

  const handleSelectAllRuns = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]); // Deselect all
    } else {
      setSelectedRows(data.map((_, index) => index)); // Select all rows by index
    }
  };

  if (data && data.length > 0) {
    return (
      <TableContainer component={Paper} className="mt-6">
        <Typography fontWeight={600} px={1}>
          RUID {data[0].RUID}
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Checkbox
                  size="small"
                  checked={selectedRows.length === data.length}
                  indeterminate={
                    selectedRows.length > 0 && selectedRows.length < data.length
                  }
                  onChange={handleSelectAllRuns}
                />
              </TableCell>
              {Object.keys(data[0]).map((header) =>
                header === "RUID" ? null : (
                  <TableCell key={header} className="font-semibold">
                    {header}
                  </TableCell>
                )
              )}
              {/* <TableCell className="font-semibold">Acciones</TableCell> */}
              <TableCell className="font-semibold"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} selected={selectedRows.includes(index)}>
                <TableCell padding="checkbox">
                  <Checkbox
                    size="small"
                    checked={selectedRows.includes(index)}
                    onChange={() => handleSelectRow(index)}
                  />
                </TableCell>
                {Object.values(row).map((cell, cellIndex) =>
                  cellIndex === 0 ? null : cellIndex === 1 ? (
                    <TableCell key={cellIndex}>
                      {cell.split(" ")[0] + " " + cell.split(" ")[1]}
                    </TableCell>
                  ) : (
                    <TableCell key={cellIndex}>{cell}</TableCell>
                  )
                )}
                <TableCell>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteRow(index)}
                  >
                    <Trash2 size={20} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};
