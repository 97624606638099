import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Search,
  ChevronDown,
  ChevronUp,
  Phone,
  Mail,
  MapPin,
  User,
  Shield,
  Download,
} from "lucide-react";
import UserStats from "./UserStats";

const UserInfo = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });
  const [loading, setLoading] = useState(true);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, "Users");
        const userSnapshot = await getDocs(usersCollection);
        const usersList = userSnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setUsers(usersList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (!a[sortConfig.key]) return 1;
    if (!b[sortConfig.key]) return -1;

    const comparison = a[sortConfig.key]
      .toString()
      .localeCompare(b[sortConfig.key].toString());
    return sortConfig.direction === "asc" ? comparison : -comparison;
  });

  const filteredUsers = sortedUsers.filter(
    (user) =>
      user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.nickName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const exportToCSV = () => {
    setExportLoading(true);
    try {
      // Definir las columnas que queremos exportar
      const headers = [
        "Nombre",
        "Nickname",
        "Email",
        "Teléfono",
        "Ciudad",
        "Estado",
        "País",
        "Tipo",
        "Estado Privado",
        "Nuevo Usuario",
        "Contactos de Emergencia",
      ];

      // Preparar los datos
      const csvData = filteredUsers.map((user) => {
        const emergencyContacts = (user.emergencyContacts || [])
          .map((contact) => `${contact.name} (${contact.phoneNumber})`)
          .join(" | ");

        return [
          user.name || "",
          user.nickName || "",
          user.email || "",
          user.phoneNumber || "",
          user.city || "",
          user.state || "",
          user.country || "",
          user.type || "",
          user.private ? "Privado" : "Público",
          user.newuser ? "Sí" : "No",
          emergencyContacts,
        ];
      });

      // Convertir a formato CSV
      const csvContent = [
        headers.join(","),
        ...csvData.map((row) => row.map((cell) => `"${cell}"`).join(",")),
      ].join("\n");

      // Crear el archivo y descargarlo
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `usuarios_${new Date().toISOString().split("T")[0]}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting CSV:", error);
    } finally {
      setExportLoading(false);
    }
  };

  const SortIcon = ({ column }) => {
    if (sortConfig.key !== column)
      return <ChevronDown className="w-4 h-4 opacity-30" />;
    return sortConfig.direction === "asc" ? (
      <ChevronUp className="w-4 h-4" />
    ) : (
      <ChevronDown className="w-4 h-4" />
    );
  };

  const TableHeader = ({ text, column }) => (
    <th
      className="px-4 py-3 cursor-pointer hover:bg-gray-50"
      onClick={() => handleSort(column)}
    >
      <div className="flex items-center gap-1">
        {text}
        <SortIcon column={column} />
      </div>
    </th>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 p-4">
      <div className="max-w-7xl mx-auto">
        {/* Header y búsqueda */}
        <div className="bg-white rounded-2xl shadow-lg p-6 mb-6">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6">
            <div className="flex flex-col gap-2">
              <h1 className="text-2xl font-bold text-gray-800">
                Usuarios Registrados
              </h1>
              <div className="text-sm text-gray-500">
                {filteredUsers.length} usuarios encontrados
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-4 w-full md:w-auto">
              <div className="relative w-full md:w-96">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type="text"
                  placeholder="Buscar usuarios..."
                  className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <button
                onClick={exportToCSV}
                disabled={exportLoading || filteredUsers.length === 0}
                className={`flex items-center justify-center gap-2 px-4 py-2 rounded-lg text-white transition-colors
                  ${
                    exportLoading || filteredUsers.length === 0
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-blue-500 hover:bg-blue-600"
                  }`}
              >
                {exportLoading ? (
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                ) : (
                  <Download className="w-5 h-5" />
                )}
                Exportar CSV
              </button>
            </div>
          </div>

          {/* Resto del componente (tabla y estadísticas) se mantiene igual */}
          {loading ? (
            <div className="text-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
              <p className="mt-4 text-gray-600">Cargando usuarios...</p>
            </div>
          ) : (
            /* ... Contenido de la tabla existente ... */
            <div className="overflow-x-auto">
              <table className="w-full border-collapse">
                <thead className="bg-gray-50 text-left text-sm text-gray-600">
                  <tr className="border-b">
                    <TableHeader text="Nombre" column="name" />
                    <TableHeader text="Nickname" column="nickName" />
                    <TableHeader text="Ciudad" column="city" />
                    <TableHeader text="Estado" column="state" />
                    <th className="px-4 py-3">Contacto</th>
                    <th className="px-4 py-3">Contactos Emergencia</th>
                    <th className="px-4 py-3">Estado</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100">
                  {filteredUsers.map((user) => (
                    <tr key={user.id} className="hover:bg-gray-50">
                      <td className="px-4 py-3">
                        <div className="flex items-center gap-3">
                          {user.photoUrl ? (
                            <img
                              src={user.photoUrl}
                              alt={user.name}
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          ) : (
                            <User className="w-10 h-10 p-2 bg-gray-100 rounded-full text-gray-600" />
                          )}
                          <div>
                            <div className="font-medium text-gray-800">
                              {user.name}
                            </div>
                            <div className="text-sm text-gray-500">
                              {user.email}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-4 py-3 text-gray-600">
                        {user.nickName}
                      </td>
                      <td className="px-4 py-3">
                        <div className="flex items-center gap-1">
                          <MapPin className="w-4 h-4 text-gray-400" />
                          <span className="text-gray-600">{user.city}</span>
                        </div>
                      </td>
                      <td className="px-4 py-3 text-gray-600">{user.state}</td>
                      <td className="px-4 py-3">
                        <div className="flex flex-col gap-1">
                          <div className="flex items-center gap-1 text-sm">
                            <Phone className="w-4 h-4 text-gray-400" />
                            <span className="text-gray-600">
                              {user.phoneNumber}
                            </span>
                          </div>
                          <div className="flex items-center gap-1 text-sm">
                            <Mail className="w-4 h-4 text-gray-400" />
                            <span className="text-gray-600">{user.email}</span>
                          </div>
                        </div>
                      </td>
                      <td className="px-4 py-3">
                        <div className="flex flex-col gap-1">
                          {user.emergencyContacts?.map((contact, index) => (
                            <div key={index} className="text-sm">
                              <div className="font-medium text-gray-700">
                                {contact.name}
                              </div>
                              <div className="text-gray-500">
                                {contact.phoneNumber}
                              </div>
                            </div>
                          ))}
                        </div>
                      </td>
                      <td className="px-4 py-3">
                        <div className="flex items-center gap-2">
                          <Shield
                            className={`w-4 h-4 ${
                              user.private ? "text-red-500" : "text-green-500"
                            }`}
                          />
                          <span
                            className={`text-sm ${
                              user.private ? "text-red-500" : "text-green-500"
                            }`}
                          >
                            {user.private ? "Privado" : "Público"}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {filteredUsers.length === 0 && (
                <div className="text-center py-12 text-gray-500">
                  No se encontraron usuarios que coincidan con la búsqueda
                </div>
              )}
            </div>
          )}
        </div>

        {/* Estadísticas */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          <div className="bg-white rounded-xl shadow-lg p-6">
            <div className="text-sm text-gray-500">Total Usuarios</div>
            <div className="text-2xl font-bold text-gray-800">
              {users.length}
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-lg p-6">
            <div className="text-sm text-gray-500">Usuarios Privados</div>
            <div className="text-2xl font-bold text-gray-800">
              {users.filter((user) => user.private).length}
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-lg p-6">
            <div className="text-sm text-gray-500">Nuevos Usuarios</div>
            <div className="text-2xl font-bold text-gray-800">
              {users.filter((user) => user.newuser).length}
            </div>
          </div>
        </div>
      </div>
      <UserStats users={users} />
    </div>
  );
};

export default UserInfo;
