import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Chip,
} from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";

const EventList = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsCollection = collection(db, "events");
      const eventsSnapshot = await getDocs(eventsCollection);
      const eventsData = eventsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        dateTime: doc.data().dateTime ? new Date(doc.data().dateTime) : null,
      }));

      const eventsWithDate = eventsData.filter((event) => event.dateTime);
      setEvents(eventsWithDate);
    };

    fetchEvents();
  }, []);

  const handleEventClick = (eventId) => {
    navigate(`/home/geekdashboard/finish-event/${eventId}`);
  };

  return (
    <Container sx={{ mt: 3, mb: 6 }}>
      <Grid container spacing={4}>
        {events.map((event) => (
          <Grid item xs={12} sm={6} md={4} key={event.id}>
            <Card
              sx={{
                borderRadius: 2,
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                overflow: "hidden",
                transition: "all 0.3s ease",
                cursor: "pointer",
                "&:hover": {
                  boxShadow: "0 6px 25px rgba(0, 0, 0, 0.15)",
                },
              }}
              onClick={() => handleEventClick(event.id)}
            >
              <CardMedia
                component="img"
                image={event.photo || "/default-event.jpg"}
                alt={`Foto del evento ${event.id}`}
                sx={{
                  height: 200,
                  objectFit: "cover",
                }}
              />
              <CardContent
                sx={{
                  textAlign: "center",
                  backgroundColor: "#f9f9f9",
                  padding: "16px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", mb: 1, color: "#1976d2" }}
                >
                  {event.eventName ? event.eventName : "Evento Desconocido"}
                </Typography>
                <Typography variant="body2" sx={{ color: "#555", mb: 1 }}>
                  {event.dateTime
                    ? event.dateTime.toLocaleDateString()
                    : "Fecha Desconocida"}
                </Typography>
                <Chip
                  icon={
                    event.price && event.price > 0 ? (
                      <PaidIcon />
                    ) : (
                      <FreeBreakfastIcon />
                    )
                  }
                  label={
                    event.price && event.price > 0
                      ? `$${event.price}`
                      : "Gratuito"
                  }
                  size="small"
                  sx={{
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default EventList;
