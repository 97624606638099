import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Trash, Search, Download } from "lucide-react";
import format from "date-fns/format";

export const OrderStatus = Object.freeze({
  approved: "approved",
  in_process: "in_process",
});

const PaymentMethods = Object.freeze({
  1: "SpeedCoins",
  2: "Stripe",
  3: "MercadoPago",
});

const OrderControl = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [searchType, setSearchType] = useState("");
  const [searchPaymentMethods, setSearchPaymentMethods] = useState([]);
  const [availableEvents, setAvailableEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [profit, setProfit] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "orders"), (snapshot) => {
      handleSearch(snapshot);
    });

    return () => unsubscribe();
  }, [
    searchQuery,
    searchStatus,
    searchType,
    searchPaymentMethods,
    selectedEvent,
  ]);

  const handleSearch = async (snapshot) => {
    setLoading(true);
    try {
      const ordersData = await Promise.all(
        snapshot.docs.map(async (orderDoc) => {
          const orderData = { id: orderDoc.id, ...orderDoc.data() };

          // Retrieve category and item names for display purposes
          const categoryRef = doc(
            db,
            "events",
            orderData.itemId,
            "categories",
            orderData.categoryId
          );
          const categorySnap = await getDoc(categoryRef);
          orderData.categoryName = categorySnap.exists()
            ? categorySnap.data().name
            : "No disponible";

          const itemRef = doc(db, "events", orderData.itemId);
          const itemSnap = await getDoc(itemRef);
          orderData.itemName = itemSnap.exists()
            ? itemSnap.data().eventName
            : "Evento no disponible";

          // Obtener el email y nombre del usuario desde Users/(UID)
          const userRef = doc(db, "Users", orderData.userId);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();
            orderData.userEmail = userData.email || "Correo no disponible";
            orderData.userName = userData.name || "Nombre no disponible";
          } else {
            orderData.userEmail = "Correo no disponible";
            orderData.userName = "Nombre no disponible";
          }

          // Formatear la fecha de creación
          orderData.createdAtFormatted = orderData.createdAt
            ? format(orderData.createdAt.toDate(), "dd/MM/yyyy")
            : "Fecha no disponible";

          return orderData;
        })
      );

      // Filtrar órdenes solo si hay algún filtro seleccionado
      const filteredOrders =
        searchQuery ||
        searchStatus ||
        searchType ||
        searchPaymentMethods.length ||
        selectedEvent
          ? ordersData.filter((order) => {
              const queryMatch = searchQuery
                ? order.id.startsWith(searchQuery) ||
                  order.userEmail.includes(searchQuery) ||
                  order.userName
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                : true;
              const statusMatch = searchStatus
                ? order.status === searchStatus
                : true;
              const typeMatch = searchType ? order.type === searchType : true;
              const paymentMethodMatch = searchPaymentMethods.length
                ? searchPaymentMethods.includes(order.paymentMethod.toString())
                : true;
              const eventMatch = selectedEvent
                ? order.itemId === selectedEvent
                : true;

              return (
                queryMatch &&
                statusMatch &&
                typeMatch &&
                paymentMethodMatch &&
                eventMatch
              );
            })
          : ordersData;

      setOrders(filteredOrders);

      // Calcular el profit si un evento está seleccionado
      if (selectedEvent) {
        const eventProfit = filteredOrders.reduce(
          (sum, order) => sum + (order.total || 0),
          0
        );
        setProfit(eventProfit);
      } else {
        setProfit(0);
      }

      // Obtener lista de eventos disponibles con sus nombres
      const eventsMap = new Map();
      ordersData.forEach((order) => {
        if (!eventsMap.has(order.itemId)) {
          eventsMap.set(order.itemId, order.itemName);
        }
      });
      setAvailableEvents(
        Array.from(eventsMap.entries()).map(([id, name]) => ({ id, name }))
      );
    } catch (error) {
      console.error("Error al buscar órdenes:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleSelectOrder = (orderId) => {
    setSelectedOrders((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId]
    );
  };

  const handleDeleteSelected = async () => {
    if (
      window.confirm(
        `¿Estás seguro de eliminar las siguientes órdenes?\n${selectedOrders.join(
          ", "
        )}`
      )
    ) {
      try {
        setLoading(true);
        await Promise.all(
          selectedOrders.map((orderId) =>
            orderId ? deleteDoc(doc(db, "orders", orderId)) : null
          )
        );
        setSuccessMessage("Órdenes eliminadas correctamente.");
        setSelectedOrders([]);
        setTimeout(() => setSuccessMessage(""), 3000);
      } catch (error) {
        console.error("Error al eliminar órdenes:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleExportCSV = () => {
    // Convertir órdenes a CSV
    const csvHeaders = [
      "ID",
      "Usuario",
      "Correo",
      "Evento",
      "Categoría",
      "Método de Pago",
      "Estado",
      "Total",
      "Fecha",
    ];

    const csvData = orders.map((order) => [
      order.id,
      order.userName,
      order.userEmail,
      order.itemName,
      order.categoryName,
      PaymentMethods[order.paymentMethod] || "No disponible",
      order.status === "approved" ? "Aprobado" : "En Proceso",
      order.total,
      order.createdAtFormatted,
    ]);

    // Crear CSV
    const csvContent = [
      csvHeaders.join(","),
      ...csvData.map((row) =>
        row
          .map(
            (value) =>
              // Escapar comas y comillas
              `"${String(value).replace(/"/g, '""')}"`
          )
          .join(",")
      ),
    ].join("\n");

    // Descargar CSV
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "ordenes_exportadas.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const togglePaymentMethod = (method) => {
    setSearchPaymentMethods((prev) =>
      prev.includes(method)
        ? prev.filter((m) => m !== method)
        : [...prev, method]
    );
  };

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      {/* Header */}
      <div className="flex justify-between items-center mb-4 bg-white p-4 rounded-lg shadow-md">
        <h1 className="text-2xl font-semibold text-gray-700">
          Gestionar Órdenes
        </h1>
        <div className="flex items-center space-x-2">
          {orders.length > 0 && (
            <button
              onClick={handleExportCSV}
              className="text-green-500 hover:text-green-700 focus:outline-none"
              title="Exportar a CSV"
            >
              <Download className="w-5 h-5" />
            </button>
          )}
          <button
            onClick={() => {
              setSearchQuery("");
              setSearchStatus("");
              setSearchType("");
              setSearchPaymentMethods([]);
              setSelectedEvent("");
            }}
            className="text-red-500 hover:text-red-700 focus:outline-none"
            title="Borrar filtros"
          >
            <Trash className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Success Message */}
      {successMessage && (
        <div className="mb-4 text-green-600 bg-green-100 p-2 rounded-md">
          {successMessage}
        </div>
      )}

      {/* Profit Display */}
      {selectedEvent && (
        <div className="mb-4 text-blue-600 bg-blue-100 p-2 rounded-md">
          Ganancias del evento seleccionado: ${profit}
        </div>
      )}

      {/* Filters */}
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-4 bg-white p-4 rounded-lg shadow-md">
        <div className="flex items-center border border-gray-300 rounded-md px-2">
          <Search className="w-5 h-5 text-gray-500 mr-2" />
          <input
            type="text"
            placeholder="Buscar por ID, correo o nombre"
            className="border-none focus:outline-none w-full p-2"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <select
          className="border border-gray-300 rounded-md p-2 focus:outline-none"
          value={searchStatus}
          onChange={(e) => setSearchStatus(e.target.value)}
        >
          <option value="">Todos los estados</option>
          <option value={OrderStatus.approved}>Aprobado</option>
          <option value={OrderStatus.in_process}>En Proceso</option>
        </select>
        <select
          className="border border-gray-300 rounded-md p-2 focus:outline-none"
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
        >
          <option value="">Todos los tipos</option>
          <option value="event">Evento</option>
          <option value="product">Producto</option>
        </select>
        <div className="border border-gray-300 rounded-md p-2">
          <div>Métodos de Pago</div>
          {Object.entries(PaymentMethods).map(([key, value]) => (
            <label key={key} className="block">
              <input
                type="checkbox"
                checked={searchPaymentMethods.includes(key)}
                onChange={() => togglePaymentMethod(key)}
                className="mr-2"
              />
              {value}
            </label>
          ))}
        </div>
        <select
          className="border border-gray-300 rounded-md p-2 focus:outline-none"
          value={selectedEvent}
          onChange={(e) => setSelectedEvent(e.target.value)}
        >
          <option value="">Todos los eventos</option>
          {availableEvents.map((event) => (
            <option key={event.id} value={event.id}>
              {event.name}
            </option>
          ))}
        </select>
      </div>

      {/* Delete Selected Orders */}
      {selectedOrders.length > 0 && (
        <div className="mb-4 bg-white p-4 rounded-lg shadow-md">
          <button
            onClick={handleDeleteSelected}
            className="bg-red-600 text-white rounded-md p-2 hover:bg-red-700 transition focus:outline-none"
            disabled={loading}
          >
            Eliminar Órdenes Seleccionadas
          </button>
        </div>
      )}

      {/* Orders Table */}
      <div className="overflow-auto rounded-lg shadow-md bg-white">
        {loading ? (
          <div className="flex justify-center p-8">
            <div className="loader border-t-4 border-blue-500 w-10 h-10 rounded-full animate-spin"></div>
          </div>
        ) : (
          <table className="w-full">
            <thead>
              <tr className="bg-gray-200 text-gray-600 text-sm leading-normal">
                <th className="py-3 px-6 text-left">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setSelectedOrders(
                        e.target.checked ? orders.map((order) => order.id) : []
                      )
                    }
                    checked={
                      selectedOrders.length > 0 &&
                      selectedOrders.length === orders.length
                    }
                    className="focus:outline-none"
                  />
                </th>
                <th className="py-3 px-6 text-left">ID</th>
                <th className="py-3 px-6 text-left">Usuario</th>
                <th className="py-3 px-6 text-left">Correo</th>
                <th className="py-3 px-6 text-left">Evento</th>
                <th className="py-3 px-6 text-left">Categoría</th>
                <th className="py-3 px-6 text-left">Método de Pago</th>
                <th className="py-3 px-6 text-left">Estado</th>
                <th className="py-3 px-6 text-left">Total</th>
                <th className="py-3 px-6 text-left">Fecha</th>
              </tr>
            </thead>
            <tbody className="text-gray-700 text-sm font-light">
              {orders.map((order) => (
                <tr
                  key={order.id}
                  className="border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
                  onClick={() =>
                    navigate(`/home/geekdashboard/ordercontrol/${order.id}`)
                  }
                >
                  <td className="py-2 px-4">
                    <input
                      type="checkbox"
                      checked={selectedOrders.includes(order.id)}
                      onChange={() => toggleSelectOrder(order.id)}
                      onClick={(e) => e.stopPropagation()}
                      className="focus:outline-none"
                    />
                  </td>
                  <td className="py-2 px-4">{order.id.slice(0, 5)}</td>
                  <td className="py-2 px-4">{order.userName}</td>
                  <td className="py-2 px-4">{order.userEmail}</td>
                  <td className="py-2 px-4">{order.itemName}</td>
                  <td className="py-2 px-4">{order.categoryName}</td>
                  <td className="py-2 px-4">
                    {PaymentMethods[order.paymentMethod] || "No disponible"}
                  </td>
                  <td className="py-2 px-4">
                    <span
                      className={`px-3 py-1 rounded-full text-xs ${
                        order.status === "approved"
                          ? "bg-green-100 text-green-700"
                          : "bg-blue-100 text-blue-700"
                      }`}
                    >
                      {order.status === "approved" ? "Aprobado" : "En Proceso"}
                    </span>
                  </td>
                  <td className="py-3 px-6 font-bold">${order.total}</td>
                  <td className="py-3 px-6 text-gray-500">
                    {order.createdAtFormatted}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default OrderControl;
