import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  Box,
  Avatar,
  Tooltip,
  Button,
  Badge,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LandscapeIcon from "@mui/icons-material/Landscape";
import QrCode2 from "@mui/icons-material/QrCode2";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import BuildIcon from "@mui/icons-material/Build";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import EventIcon from "@mui/icons-material/Event";
import MapIcon from "@mui/icons-material/Map";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import Logout from "@mui/icons-material/Logout";
import SearchIcon from "@mui/icons-material/Search";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useUserAuth } from "./context/userAuthContext";
import "./Navbar.css";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "./firebase";
import Notificaciones from "./Notificaciones";

const NavbarComponent = () => {
  const navigate = useNavigate();
  const { user, logOut } = useUserAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [photoURL, setPhotoURL] = useState("");
  const [userType, setUserType] = useState([]);
  const [friendRequestsCount, setFriendRequestsCount] = useState(0);

  // Estado para el menú de notificaciones
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const notificationsOpen = Boolean(notificationsAnchorEl);

  useEffect(() => {
    if (user && user.uid) {
      const docRef = doc(db, "Users", user.uid);
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const userData = docSnap.data();
          if (userData) {
            setPhotoURL(userData.photoUrl || "");
            setUserType(userData.type || []);
            // Obtener la cantidad de solicitudes de amistad
            if (
              userData.friendRequests &&
              Array.isArray(userData.friendRequests)
            ) {
              setFriendRequestsCount(userData.friendRequests.length);
            } else {
              setFriendRequestsCount(0);
            }
          } else {
            setPhotoURL("");
            setUserType([]);
            setFriendRequestsCount(0);
          }
        } else {
          console.log("No such document!");
          setPhotoURL("");
          setUserType([]);
          setFriendRequestsCount(0);
        }
      });

      return () => unsubscribe();
    }
  }, [user]);

  const navItems = [
    { label: "Search", path: "/search", icon: <SearchIcon /> },
    { label: "Home", path: "/home", icon: <DashboardIcon /> },
    { label: "Mis Tickets", path: "/tickets", icon: <QrCode2 /> },
    { label: "Solicitudes", path: "/friendsrequests", icon: <GroupAddIcon /> },
    { label: "Perfil", path: "/Profile", icon: <AccountCircleIcon /> },
    { label: "Parques", path: "/parks", icon: <LandscapeIcon /> },
    { label: "Equipos", path: "/teams", icon: <Diversity3Icon /> },
    { label: "Workshops", path: "/workshops", icon: <BuildIcon /> },
    { label: "Marketplace", path: "/marketplace", icon: <ShoppingCartIcon /> },
    { label: "Leaderboards", path: "/Leaderboards", icon: <LeaderboardIcon /> },
    { label: "Eventos", path: "/events", icon: <EventIcon /> },
    { label: "Mapa en vivo", path: "/livemap", icon: <MapIcon /> },
    { label: "Transmisiones en Vivo", path: "/live", icon: <LiveTvIcon /> },
  ];

  const restrictedNavItems = [
    { label: "Iniciar Sesión", path: "/login", icon: <AccountCircleIcon /> },
    { label: "Registro", path: "/signup", icon: <AccountCircleIcon /> },
  ];

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Maneja el clic en el icono de notificaciones
  const handleNotificationsClick = (event) => {
    setNotificationsAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setNotificationsAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/home");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <AppBar position="fixed" color="default" elevation={0}>
      <Toolbar>
        <Link to="/home" className="navbar-brand">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/LiveSpeed.png?alt=media&token=f30dbc3b-6932-486d-b992-3a705f04f0b5"
            alt="Logo"
            className="Logo"
            style={{ maxWidth: 160 }}
          />
        </Link>
        <div className="spacer" />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {userType && userType.includes("admin") && (
            <Button
              variant="contained"
              color="error"
              startIcon={<AdminPanelSettingsIcon />}
              sx={{ mr: 2, px: 1, py: 0.5, minWidth: "auto", fontSize: 12 }}
              onClick={() => navigate("home/geekdashboard")}
            >
              Admin
            </Button>
          )}
          {/* Icono de notificaciones con badge */}
          <IconButton
            onClick={handleNotificationsClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={notificationsOpen ? "notifications-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={notificationsOpen ? "true" : undefined}
          >
            <Badge
              badgeContent={friendRequestsCount}
              color="error"
              invisible={friendRequestsCount === 0}
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Menu
            anchorEl={notificationsAnchorEl}
            id="notifications-menu"
            open={notificationsOpen}
            onClose={handleNotificationsClose}
            onClick={handleNotificationsClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Notificaciones />
          </Menu>
          {/* Configuración de la cuenta */}
          <Tooltip title="Configuración de la cuenta">
            <IconButton
              onClick={handleMenuClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                sx={{ width: 32, height: 32 }}
                src={photoURL ?? undefined}
              >
                {!photoURL && <MenuIcon />}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {(user ? navItems : restrictedNavItems).map((item) => (
              <MenuItem key={item.path} component={Link} to={item.path}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                {item.label}
              </MenuItem>
            ))}
            {user && (
              <Box>
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Cerrar Sesión
                </MenuItem>
              </Box>
            )}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavbarComponent;
