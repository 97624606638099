import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import {
  Calendar,
  Clock,
  MapPin,
  DollarSign,
  Users,
  Share2,
  Lock,
  MessagesSquare,
} from "lucide-react";
import EventParticipants from "./EventParticipants";
import { eventsApi } from "../../lib/client";
import EventStats from "./EventStats";
import EventChat from "./EventChat";

const EventDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useUserAuth();
  const [event, setEvent] = useState(null);
  const [userIsParticipant, setUserIsParticipant] = useState(false);
  const [showRegisterWarning, setShowRegisterWarning] = useState(false);

  useEffect(() => {
    const fetchEventData = async () => {
      const eventData = await eventsApi.getEventById(id, {
        categories: true,
        creator: true,
        participants: true,
      });

      if (user && eventData.participants) {
        const isParticipant = eventData.participants.some(
          (p) => p.user.uid === user.uid
        );
        setUserIsParticipant(isParticipant);
      }

      setEvent(eventData);
    };

    fetchEventData();
  }, [id, user]);

  const handleJoinEvent = () => {
    if (!user) {
      setShowRegisterWarning(true);
      return;
    }
    navigate(`/payevent/${id}`);
  };

  const handleLeaveEvent = async () => {
    try {
      await eventsApi.removeParticipantFromEvent(id, user.uid);
      setUserIsParticipant(false);
    } catch (error) {
      console.error("Error leaving event:", error);
    }
  };

  const handleShare = () => {
    const eventUrl = window.location.href;
    const shareData = {
      title: event.eventName,
      text: `${event.eventName}\nFecha: ${new Date(
        event.dateTime
      ).toLocaleDateString()} a las ${new Date(
        event.dateTime
      ).toLocaleTimeString()}\nCosto: ${
        event.price > 0 ? `$${event.price}` : "Gratis"
      }`,
      url: eventUrl,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .catch((error) => console.error("Error sharing:", error));
    } else {
      navigator.clipboard.writeText(
        `${shareData.title}\n${shareData.text}\n${shareData.url}`
      );
      alert("¡Enlace y detalles copiados al portapapeles!");
    }
  };

  const handleSupport = () => {
    const eventUrl = window.location.href;
    const whatsappUrl = `https://wa.me/526622995258?text=Hola!%20Necesito%20soporte%20en%20${eventUrl}%20!!!`;
    window.open(whatsappUrl, "_blank");
  };

  if (!event) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-gray-50 min-h-screen">
      <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
        {/* Image Section */}
        <div className="relative aspect-[9/16] w-full max-h-[700px] bg-gray-100">
          <img
            src={event.photo}
            alt={event.eventName}
            className="absolute inset-0 w-full h-full object-contain"
          />
        </div>

        {/* Content Section */}
        <div className="p-6 md:p-8">
          {/* Event Status Badge */}
          {event.closed && (
            <div className="inline-flex items-center gap-2 bg-red-100 text-red-700 px-4 py-2 rounded-full mb-4">
              <Lock className="w-4 h-4" />
              <span className="font-medium">Inscripciones cerradas</span>
            </div>
          )}

          {/* Title and Organizer */}
          <div className="mb-8">
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              {event.eventName}
            </h1>
            <OrganizerInfo creator={event.creator} />
          </div>

          {/* Event Information Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <InfoCard
              icon={<Calendar className="w-5 h-5" />}
              label="Fecha"
              value={new Date(event.dateTime).toLocaleDateString()}
            />
            <InfoCard
              icon={<Clock className="w-5 h-5" />}
              label="Hora"
              value={new Date(event.dateTime).toLocaleTimeString()}
            />
            <InfoCard
              icon={<MapPin className="w-5 h-5" />}
              label="Ubicación"
              value="Ver en mapa"
              className="cursor-pointer hover:bg-gray-50"
            />
            {event.price > 0 && (
              <InfoCard
                icon={<DollarSign className="w-5 h-5" />}
                label="Precio"
                value={
                  <span className="inline-flex items-center bg-gray-900 text-white px-3 py-1 rounded-full text-sm font-medium">
                    ${event.price}
                  </span>
                }
              />
            )}
          </div>

          {/* Description */}
          <div className="mb-8">
            <h2 className="text-lg font-semibold text-gray-900 mb-2">
              Descripción
            </h2>
            <p className="text-gray-700 leading-relaxed">{event.description}</p>
          </div>

          {/* Action Buttons */}
          <div className="flex flex-col sm:flex-row gap-3">
            {!event.closed ? (
              <button
                onClick={userIsParticipant ? handleLeaveEvent : handleJoinEvent}
                className={`flex-1 py-2.5 px-4 rounded-lg font-medium transition-colors ${
                  userIsParticipant
                    ? "bg-red-500 hover:bg-red-600 text-white"
                    : "bg-gray-900 hover:bg-gray-800 text-white"
                }`}
                disabled={event.closed}
              >
                {userIsParticipant ? "Salir del evento" : "Unirse al evento"}
              </button>
            ) : (
              <button
                disabled
                className="flex-1 py-2.5 px-4 bg-gray-300 text-gray-600 rounded-lg font-medium cursor-not-allowed inline-flex items-center justify-center"
              >
                <Lock className="w-4 h-4 mr-2" />
                Inscripciones cerradas
              </button>
            )}

            <button
              onClick={handleShare}
              className="flex-1 py-2.5 px-4 border border-gray-300 text-gray-700 rounded-lg font-medium hover:bg-gray-50 transition-colors inline-flex items-center justify-center"
            >
              <Share2 className="w-4 h-4 mr-2" />
              Compartir
            </button>

            <button
              onClick={handleSupport}
              className="flex-1 py-2.5 px-4 bg-green-500 hover:bg-green-600 text-white rounded-lg font-medium transition-colors inline-flex items-center justify-center"
            >
              <MessagesSquare className="w-4 h-4 mr-2" />
              Necesitas ayuda?
            </button>
          </div>
        </div>
      </div>

      {/* Participants Section */}
      <div className="mt-8 bg-white rounded-2xl shadow-lg p-6">
        <div className="flex items-center gap-2 mb-6">
          <Users className="w-5 h-5 text-gray-700" />
          <h2 className="text-xl font-semibold text-gray-900">Participantes</h2>
        </div>
        <EventParticipants
          participants={event.participants.map((p) => {
            const categoryFound = event.categories.find(
              (c) => c.id === p.categoryId
            );
            return {
              id: p.id,
              joinedAt: p.joinedAt,
              category: categoryFound.name,
              user: p.user,
            };
          })}
        />
      </div>

      {/* Chat Section */}
      <div className="mt-8 bg-white rounded-2xl shadow-lg overflow-hidden">
        <EventChat uid={id} />
      </div>

      {/* Stats Section */}
      <div className="mt-8 bg-white rounded-2xl shadow-lg overflow-hidden">
        <EventStats
          latitude={event.location.lat}
          longitude={event.location.lng}
          eventDate={event.dateTime}
        />
      </div>

      {/* Register Dialog */}
      {showRegisterWarning && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-2xl max-w-md w-full p-6">
            <h3 className="text-xl font-semibold text-center text-gray-900 mb-4">
              Bienvenido
            </h3>
            <p className="text-gray-600 text-center mb-6">
              Regístrate o inicia sesión para unirte o pagar por el evento.
            </p>
            <div className="flex flex-col gap-3">
              <button
                onClick={() => navigate("/login")}
                className="w-full py-2.5 px-4 bg-gray-900 text-white rounded-lg font-medium hover:bg-gray-800 transition-colors"
              >
                Iniciar sesión
              </button>
              <button
                onClick={() => navigate("/signup")}
                className="w-full py-2.5 px-4 border border-gray-300 text-gray-700 rounded-lg font-medium hover:bg-gray-50 transition-colors"
              >
                Crear cuenta
              </button>
              <button
                onClick={() => setShowRegisterWarning(false)}
                className="w-full py-2.5 px-4 text-gray-500 rounded-lg font-medium hover:bg-gray-100 transition-colors"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const OrganizerInfo = ({ creator }) => (
  <div className="flex items-center space-x-4">
    <div className="relative w-12 h-12">
      <img
        src={creator?.photoUrl}
        alt={creator?.name}
        className="rounded-full object-cover"
      />
    </div>
    <div>
      <p className="text-sm text-gray-500">Organizado por</p>
      <p className="font-medium text-gray-900">
        {creator?.name || "Organizador"}
      </p>
    </div>
  </div>
);

const InfoCard = ({ icon, label, value, className = "" }) => (
  <div
    className={`flex items-start space-x-3 p-4 bg-gray-50 rounded-xl ${className}`}
  >
    <div className="text-gray-700">{icon}</div>
    <div>
      <p className="text-sm text-gray-500">{label}</p>
      <p className="font-medium text-gray-900">{value}</p>
    </div>
  </div>
);

export default EventDetail;
